
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'appraisals-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('appraisalsRequestsListing'), [
          translate('appraisals'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_APPRAISALS_STORE);
      });

      await store.dispatch(Actions.GET_APPRAISALS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('applicantInfo'),
          key: 'applicantName',
          sortable: true,
        },
        {
          name: translate('appraiserName'),
          key: 'appraiserName',
          sortable: true,
        },
        {
          name: translate('areaName'),
          key: 'areaName',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'statusTitle',
          sortable: true,
        },
        {
          name: translate('price'),
          key: 'price',
          sortable: true,
        },
        {
          name: translate('createdDate'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const viewAppraisal = (id) => {
        router.push({ name: 'appraisals-viewing', params: { id } });
      };

      const editAppraisal = (id) => {
        router.push({ name: 'appraisals-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_APPRAISAL_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_APPRAISALS);
        loading.value = false;
      };
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.appraisalsList),
        tableHeader,
        viewAppraisal,
        translate,
        totalItems: computed(() => store.getters.appraisalsListCount),
        pageChanged,
        goTo,
        editAppraisal,
        loading,
        can,
      };
    },
  });
